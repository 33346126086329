import { CartPhysicalItem, ProductData } from "@/lib/5874/types";
import Cookies from "js-cookie";
import useStoreKeys from "./useStoreKeys";
import { getConfig } from "@/lib/searchspring/searchspring.config";
import { StoreRegion } from "@/lib/builder/builder.config";

const useIntelliSuggestTracking = () => {
	const { store, lang } = useStoreKeys();
	const config = getConfig(store);
	const siteId = config.globals.siteId;

	const intellisuggestTrackProductViews = (product: ProductData) => {
		// @ts-ignore
		IntelliSuggest.init({
			siteId,
			context: `Product/${product.sku}`,
			seed: [product.sku],
		});
		// @ts-ignore
		IntelliSuggest.setShopperId(Cookies.get("ssShopperId"));
		// @ts-ignore
		IntelliSuggest.viewItem({ sku: product.sku });
	};

	const intellisuggestTrackBasketContents = (products: CartPhysicalItem[]) => {
		// @ts-ignore
		IntelliSuggest.init({
			siteId,
			context: "Basket",
			seed: products.map((product) => product.sku),
		});
		// @ts-ignore
		IntelliSuggest.setShopperId(Cookies.get("ssShopperId"));
		products.forEach((product) => {
			// @ts-ignore
			IntelliSuggest.haveItem({
				sku: product.sku,
				qty: product.quantity,
				price: product.list_price,
			});
		});
		// @ts-ignore
		IntelliSuggest.inBasket({});
	};

	// const intellisuggestTrackProductClick = (
	// 	element: HTMLElement,
	// 	data: string,
	// 	signature: string,
	// ) => {
	// 	// @ts-ignore
	// 	IntelliSuggest.clickItem(element, {
	// 		siteId: siteId,
	// 		intellisuggestData: data,
	// 		intellisuggestSignature: signature,
	// 	});
	// 	console.log(`CLICKED Tracker for ${siteId}, and element: ${element}`);
	// };

	const intellisuggestTrackProductClick = (
		href: string,
		data: string,
		signature: string,
	) => {
		if (document.images) {
			const apiUrl = `https://${siteId}.a.searchspring.io/api/`;

			const imgTag = new Image();
			imgTag.src = `${apiUrl}track/track.json?d=${data}&s=${signature}&u=${encodeURIComponent(
				href,
			)}&r=${encodeURIComponent(document.referrer)}`;
		}

		return true;
	};

	return {
		productView: intellisuggestTrackProductViews,
		productClick: intellisuggestTrackProductClick,
		basketContents: intellisuggestTrackBasketContents,
	};
};

export default useIntelliSuggestTracking;
