import { productOnProduct } from "../fragments/product";

export const getProductsByEntityId = (entityIds: number[]) => {
	return {
		variables: {
			entityIds,
		},
		query: /* GraphQL */ `
			query getProductsById($entityIds: [Int!]) {
				site {
					products(entityIds: $entityIds, first: 50) {
						edges {
							node {
								...product
							}
						}
					}
				}
			}
			${productOnProduct}
		`,
	};
};
