import Image from "next/image";
import NoImageImage from "@/app/assets/images/img/placeholder.png";
import Link from "next/link";

interface CompressedImageProps {
	src: string | undefined;
	altText: string;
	height?: number;
	width?: number;
	format?: string;
	extraParams?: string;
	imgLink?: string;
	ariaLabel?: string;
	containerClass?: string;
	onClick?: () => void;
}

const CompressedImage = ({
	src,
	height,
	width,
	format = "webp",
	altText = "",
	extraParams = "",
	imgLink,
	ariaLabel,
	containerClass,
	onClick,
	...props
}: CompressedImageProps) => {
	const getImage = () => {
		let updatedSrc = src;
		// if (height !== undefined) updatedSrc = `${updatedSrc}&height=${height}`;
		// if (width !== undefined) updatedSrc = `${updatedSrc}&width=${width}`;
		// if (format !== undefined) updatedSrc = `${updatedSrc}&format=${format}`;
		// if (extraParams !== undefined) updatedSrc = `${updatedSrc}&${extraParams}`;

		if (!src) updatedSrc = NoImageImage.src;

		if (width === undefined && height === undefined) {
			return <img src={updatedSrc} alt={altText} />;
		}

		return (
			<Image
				src={updatedSrc as string}
				width={width}
				height={height}
				alt={altText}
				onClick={onClick}
				{...props}
			/>
		);
	};

	if (imgLink)
		return (
			<Link
				href={imgLink}
				className={containerClass}
				aria-label={ariaLabel}
				onClick={onClick}
				{...props}
			>
				{getImage()}
			</Link>
		);

	return <div className={containerClass}>{getImage()}</div>;
};

export default CompressedImage;
