import mapProduct from "../5874/mapper/ProductMapper";
import { bigcommerceGraphqlAPI, bigcommerceRestAPI } from "./client";
import { isValidJSON } from "@/utils/is-valid-json";
import {
	getProductByEntityId,
	getProductBySku,
} from "./graphql/queries/product";
import { getProductsByEntityId } from "./graphql/queries/products";

export const getProduct = async (entityId: number) => {
	if (!entityId) return null;

	const gql = bigcommerceGraphqlAPI<any>({
		query: getProductByEntityId(entityId),
		cache: "default",
	});

	const rest = bigcommerceRestAPI<any>({
		endpoint: `/v3/catalog/products/${entityId}?include=images,videos&include_fields=images,videos,layout_file`,
		options: {
			cache: "default",
		},
	});

	const [product, media] = await Promise.all([gql, rest]);

	if (product.status !== 200) {
		console.error(`${product.status}: Cannot return product.`);
		return;
	}

	const prod = product.data.site.product;
	const warranty = isValidJSON(prod.warranty);
	const childSkus = warranty.childSKUs;
	const sisterSkus = warranty.sisterSKUs;

	if (childSkus && childSkus.length > 0) {
		const childSkuPromises = childSkus.map(async (childProd: any) => {
			const details = await bigcommerceGraphqlAPI<any>({
				query: getProductBySku(childProd),
				cache: "default",
			});

			if (!details?.data?.site?.product?.entityId) return null;

			return mapProduct({
				...details.data.site.product,
			});
		});

		const childData = await Promise.all(childSkuPromises);

		prod.children = childData;
	}

	if (sisterSkus && sisterSkus.length > 0) {
		const sisterSkuPromises = sisterSkus.map(async (childProd: any) => {
			const details = await bigcommerceGraphqlAPI<any>({
				query: getProductBySku(childProd),
				cache: "default",
			});

			if (!details?.data?.site?.product?.entityId) return null;

			return mapProduct({
				...details.data.site.product,
			});
		});

		const siblingData = await Promise.all(sisterSkuPromises);

		prod.siblings = siblingData;
	}

	if (warranty.kitSKUs) {
		const kitSKUs = [];
		for (let i = 0; i < warranty.kitSKUs.length; i += 2) {
			kitSKUs.push({
				sku: warranty.kitSKUs[i],
				quantity: warranty.kitSKUs[i + 1].quantity,
			});
		}

		if (kitSKUs && kitSKUs.length > 0) {
			const kitSkuPromises = kitSKUs.map(async (kitProd: any) => {
				const details = await bigcommerceGraphqlAPI<any>({
					query: getProductBySku(kitProd.sku),
					cache: "default",
				});

				if (!details?.data?.site?.product?.entityId) return null;

				return mapProduct({
					...details.data.site.product,
					quantity:
						details?.data?.site?.product?.availabilityV2.status !== "Available"
							? 0
							: kitProd.quantity,
				});
			});

			// Remove the null values from the array
			const kitProductData = (await Promise.all(kitSkuPromises)).filter(
				Boolean as any as <T>(x: T | null) => x is T,
			);

			prod.kitProducts = kitProductData;
		}
	}

	return mapProduct({
		...prod,
		...media.body,
	});
};

/**
 * Retrieves an array of products from BigCommerce based on an array of SKUs.
 *
 * @param {string[]} skuArray - An array of SKUs to search for.
 * @return {Promise<Product[] | null>} - A Promise that resolves to an array of Product objects if the request is successful, or null if the request fails.
 */
export const getProductsBySkus = async (skuArray: string[]) => {
	const skuParamString = skuArray.join(",");

	const res = await bigcommerceRestAPI<any>({
		endpoint: `/v3/catalog/products?include_fields='&sku:in=${skuParamString}&include=images,videos&include_fields=images,videos,layout_file`,
	});

	if (res.status !== 200) {
		console.error(`${res.status}: Cannot return product.`);
		return null;
	}

	const ids = res.body.map((product: { id: string }) => product.id);

	const products = await getProductsByIds(ids, res);

	return products;
};

/**
 * Retrieves products by their entity IDs.
 *
 * @param {number[]} entityIds - An array of entity IDs.
 * @param {any} restResponse - (optional) The REST response. Defaults to null.
 * @return {Promise<any[]>} - A promise that resolves to an array of products.
 */
export const getProductsByIds = async (
	entityIds: number[],
	restResponse: any = null,
) => {
	if (!entityIds || !entityIds.length) return null;

	const promises = [];

	try {
		const res = bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityId(entityIds),
			cache: "default",
		});

		promises.push(res);

		if (!restResponse) {
			const rest = bigcommerceRestAPI<any>({
				endpoint: `/v3/catalog/products/?id:in=${entityIds.join(
					",",
				)}&include=images,videos&include_fields=images,videos,layout_file`,
			});

			promises.push(rest);
		}

		const [products, media = restResponse] = await Promise.all(promises);

		//@ts-ignore the data part of this is flagging and I cant see why
		const correctedProducts = products.data.site.products.edges.map(
			({ node }: any) => {
				const correspondingMediaData = media.body.find(
					(mediaObject: any) => mediaObject.id === node.entityId,
				);

				return mapProduct({
					...node,
					...correspondingMediaData,
				});
			},
		);

		const childrenDataPromises = correctedProducts.map(async (parent: any) => {
			try {
				const warranty = isValidJSON(parent.warranty);
				const childSkus = warranty.childSKUs;

				if (childSkus && childSkus.length > 0) {
					const childSkuPromises = childSkus.map(async (childProd: any) => {
						const details = await bigcommerceGraphqlAPI<any>({
							query: getProductBySku(childProd),
							cache: "default",
						});

						return mapProduct({
							...details.data.site.product,
						});
					});

					const childData = await Promise.all(childSkuPromises);

					return { ...parent, children: childData };
				}

				return parent;
			} catch (error) {
				console.error(`Error processing parent with id ${parent.id}:`, error);
				return parent;
			}
		});

		const detailedProducts = await Promise.all(childrenDataPromises);

		return detailedProducts;
	} catch (error) {
		console.error("🚀 ~ getProductsById ~ error:", error);
	}
};

//
export const getProductsNotChildrenByIds = async (
	entityIds: number[],
	restResponse: any = null,
) => {
	if (!entityIds || !entityIds.length) return null;

	const promises = [];

	try {
		const res = bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityId(entityIds),
			cache: "default",
		});

		promises.push(res);

		if (!restResponse) {
			const rest = bigcommerceRestAPI<any>({
				endpoint: `/v3/catalog/products/?id:in=${entityIds.join(
					",",
				)}&include=images,videos&include_fields=images,videos,layout_file`,
			});

			promises.push(rest);
		}

		const [products, media = restResponse] = await Promise.all(promises);

		//@ts-ignore the data part of this is flagging and I cant see why
		const correctedProducts = products.data.site.products.edges.map(
			({ node }: any) => {
				const correspondingMediaData = media.body.find(
					(mediaObject: any) => mediaObject.id === node.entityId,
				);

				return mapProduct({
					...node,
					...correspondingMediaData,
				});
			},
		);
	} catch (error) {
		console.error("🚀 ~ getProductsById ~ error:", error);
	}
};
