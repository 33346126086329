import { ClientConfig, ClientGlobals } from "@searchspring/snap-client";
import { StoreRegion } from "../builder/builder.config";

// export type StoreSearchSpringConfig = {
// 	[store: StoreRegion]: ClientGlobals;
// };

export type StoreSearchSpringConfig = typeof productionStores;

export type StoreKey = keyof typeof productionStores;

// Production and Sandbox store configurations
const productionStores = {
	eu: {
		siteId: "jkz3wg",
	},
	ca: {
		siteId: "",
	},
	global: {
		siteId: "",
	},
} as const;

const sandboxStores = {
	eu: {
		siteId: "jkz3wg",
	},
	ca: {
		siteId: "",
	},
	global: {
		siteId: "",
	},
} as const;

const clientConfig = {
	mode:
		(process.env.APP_ENV || "development") === "production"
			? "production"
			: "development",
} as ClientConfig;

// const getStores = (): StoreSearchSpringConfig => {
// 	return (process.env.APP_ENV || "development") === "production"
// 		? productionStores
// 		: sandboxStores;
// };

export const getConfig = (store: StoreRegion) => {
	return {
		globals: getStoreGlobals(store),
		clientConfig,
	};
};

export const getStoreGlobals = (region: StoreRegion) => {
	const stores =
		clientConfig.mode === "production" ? productionStores : sandboxStores;
	return stores[region];
};

// Example usage
// const euStoreGlobals = getStoreGlobals("eu");
// console.log(`${JSON.stringify(euStoreConfig)}`);
