const money = /* GraphQL */ `
    currencyCode
    value
`;

export const Price = /* GraphQL */ `
    basePrice {
        ${money}
    }
    price {
        ${money}
    }
    salePrice {
        ${money}
    }
    saved {
        ${money}
    }
    priceRange {
        min {
            ${money}
        }
        max {
            ${money}
        }
    }
    bulkPricing {
        minimumQuantity
        maximumQuantity
        ... on BulkPricingFixedPriceDiscount {
        price
        }
        ... on BulkPricingPercentageDiscount {
        percentOff
        }
        ... on BulkPricingRelativePriceDiscount {
        priceAdjustment
        }
    }
`;
